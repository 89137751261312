import React, {useState, useEffect, useCallback} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
//components
import { ScrollView, Modal } from "../../../components/Components.jsx";
import { useData } from "../../../Utils/UseContext.jsx";
import { UpdateWorkout } from "../../../Utils/ApiRequests.jsx";
//Buttons
import { AddButton } from "../../../components/Buttons.jsx";
//icon
import { IoTrash } from "react-icons/io5";

//Style
import { StyledWorkoutInput, StyledTextArea, LABEL } from "../../../components/style/CustomInput.styled.jsx";
import { WorkoutInputContainer, WorkoutInputContent } from "../StyledComponents.jsx";
import { ButtonContainer, Container, DropzoneContainer, PreviewImage, PlaceholderText, RightBottomFloatingContainer, RightTopAbsoluteFloatingDiv } from "../../../components/style/Container.styled.jsx";
import { CustomButton } from "../../../components/Buttons.jsx";
import { ColumnWrapper } from "../../../components/style/ColumnWrapper.styled.jsx";
import { StyledSubmitButton } from "../../../components/style/Buttons.styled.jsx";
import { Row } from "../../../components/style/Row.styled.jsx";

const Dropzone = ({index, image, setValues}) => {
    const [preview, setPreview] = useState(image);
    const [error, setError] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
      
            // Check file size (e.g., limit to 5MB)
            const maxSize = 5 * 1024 * 1024;
            if (file.size > maxSize) {
              setError('File size exceeds the limit of 5MB');
              return;
            }
            const previewUrl = URL.createObjectURL(file);
            setPreview(previewUrl);
            setError(null);
            setValues(prevValues => 
                prevValues.map((item, i) => 
                    i === index ? { ...item, media_url: previewUrl, file } : item
                )
            );
          }
    }, [index, setValues]);

    useEffect(() => {
        // If the component is initialized with an existing image, set it as the preview
        if (image) {
            setPreview(image);
        }
    }, [image]);

  
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ 
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.bmp', '.webp'],
            'video/*': ['.mp4', '.mov', '.avi', '.mkv', '.webm']
        }
     });
  
    return (
      <div>
        <DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
            {preview ? (
              <PreviewImage src={preview} alt="Preview" />
            ) : (
              <PlaceholderText>{isDragActive ? 'Drop the files here...' : 'Video or Image'}</PlaceholderText>
            )}
            {error && <p style={{ color: 'red', position: 'absolute', bottom: '10px' }}>{error}</p>}
        </DropzoneContainer>
      </div>
    );
  };


const EditWorkout = ({theme}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {data, workoutName, workoutId, index} = location.state || {};
    const {personalData ,setPersonalData} = useData();
    const [values, setValues] = useState([]);
    const [deletedItems, setDeletedItems] = useState([]);
    const [nameofpart, setNameofpart] = useState("")
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const [unit] = useState([
        {value: 'kg', label: 'Kilo'},
        {value: 'lb', label: 'Pounds'},
        {value: null, label: 'No unit'}
    ]);
    const [options] = useState([
        { value: 'rep', label: 'Repetitions' },
        { value: 'sek', label: 'Seconds' },
        { value: 'min', label: 'Minutes' },
        { value: 'h', label: 'Hours' }]);
    const nameOfWorkoutPart = values[0]?.part_of_workout_name || "";

    useEffect(() => {
        if(data){
            const value = Object.values(data)[0];
            setValues(value);
            setNameofpart(value[0]?.part_of_workout_name);
        }
    },[data])

    useEffect(() => {
        const anyFieldEmpty = isAnyFieldEmpty(values);
        setIsSubmitDisabled(anyFieldEmpty);
      }, [values]);

    const navTo = (values, id) => {
        navigate(`/workout/my/${id}`,{state: {values, id}});
    }

    const handleOnBlur = (name, value, index) => {
        const sanitizedValue = value.replace(new RegExp(/[\\/]/, 'g'), '');
        setValues(prevValues => 
            prevValues.map((item, i) => 
                i === index ? { ...item, [name]: sanitizedValue } : item
            )
        );
    }

    const handleCancel = () => {
        navigate('/workout/my')
        console.info("Cancel")
    }

    const handleSubmit = async (valueToDb) => {
        const partId = Object.keys(data)[0];
        const obj = {newworkout: valueToDb, partId: partId, workoutId: workoutId, deletedItems: deletedItems}
        const formData = new FormData();
        formData.append('data', JSON.stringify(obj));
        valueToDb.map((item, index) => {
            item?.file && (formData.append(`media_${index}`, item.file))
        })
        await UpdateWorkout(formData, valueToDb, setPersonalData, personalData, workoutId, workoutName, index, partId, navTo)
    }

    const handleOnPartChange = (value, name, index) => {
        const sanitizedValue = value.replace(new RegExp(/[\\/]/, 'g'), '');
        setValues(prevValues => 
             prevValues.map((item, i) => (
                 i === index ? { ...item, [name]: sanitizedValue } : item
             )
             )
         );
    } 

    const handleChange = async (e, index) => {
        const value = e.target.value;
        const name = e.target.name;

        if(name === 'option'){
            setValues(prevValues => 
                prevValues.map((item, i) => (
                    i === index ? { ...item, [name]: value } : item
                )
                ))
        }else if(name === 'unit'){
            setValues(prevValues => 
                prevValues.map((item, i) => (
                    i === index ? { ...item, [name]: value } : item
                )
                ))
        }
    };

    const dropdown = (options, name, value, onChange, index) => {
    
        return(
            <select name={name} style={{background: 'transparent', border: 'none', height: '3em'}} value={value} onChange={(e) => onChange(e, index)} >
                {options.map((option, index) => (
                  <option  key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
        )
    }

    const addEmptyItem = () => {
        const emptyItem = {
                NameOfExercise:"",
                amount: "",
                description:"",
                media_url:"",
                option:"rep",
                times:"",
                unit:"kg",
                weight:""
        }

        setValues([...values, emptyItem])
    }

    const removeItem = (indexToRemove) => {
        const newValues = values.filter((item, index) => index !== indexToRemove);
        const deletedItemId = values[indexToRemove]?.ExerciseId; // Ota ID talteen

        setValues(newValues); // Päivitetään tila ilman poistettua kohdetta
        if (deletedItemId) {
            setDeletedItems([...deletedItems, deletedItemId]); // Lisää ID poistettavien listaan
        }
    };

    const isAnyFieldEmpty = (items) => {
        return items.slice(1).some(item => 
            !item.NameOfExercise || item.NameOfExercise.trim() === ""
        );
      }

     const Content = ({item, index}) => {
        const [text, setText] = useState("")
        const [focused, setFocused] = useState({"": false})

        const handleOnFocus = (value, key) => {
            setFocused({[key]: true});
            setText(value);
          }

        const handleOnChange = (value) => {
            setText(value)
        }

        return (
                //  <ColumnWrapper key={index}>
                     <WorkoutInputContent align={'center'} position={'relative'} border={'solid'}>
                        <RightTopAbsoluteFloatingDiv>
                            <IoTrash onClick={() => removeItem(index)} size={20}/>
                        </RightTopAbsoluteFloatingDiv>
                        <LABEL>Name</LABEL>
                         <StyledWorkoutInput
                             name="NameOfExercise"
                             type="text"
                             value={focused['NameOfExercise'] ? text : item?.NameOfExercise}
                             onFocus={(e) => (handleOnFocus(e.target.value, e.target.name))}
                             onBlur={(e) => handleOnBlur(e.target.name, e.target.value, index)}
                             onChange={(e) => handleOnChange(e.target.value)}
                         />
                         <LABEL>Amount</LABEL>
                         <Row width={'fit-content'}>
                         <StyledWorkoutInput
                             name="amount"
                             type="number"
                             value={focused['amount'] ? text : item.amount}
                             onChange={(e) => handleOnChange(e.target.value)}
                             onFocus={(e) => (handleOnFocus(e.target.value, e.target.name))}
                             onBlur={(e) => handleOnBlur(e.target.name, e.target.value, index)}
                         />
                        {dropdown(options, 'option', item.option, handleChange, index)}
                        </Row>
                         <LABEL>Rounds</LABEL>
                         <StyledWorkoutInput
                             name="times"
                             type="number"
                             value={focused['times'] ? text : item.times}
                             onChange={(e) => handleOnChange(e.target.value)}
                             onFocus={(e) => (handleOnFocus(e.target.value, e.target.name))}
                             onBlur={(e) => handleOnBlur(e.target.name, e.target.value, index)}
                         />
                         <LABEL>Weight</LABEL>
                         <Row width={'fit-content'}>
                         <StyledWorkoutInput
                             name="weight"
                             type="number"
                             value={focused['weight'] ? text : item.weight}
                             onChange={(e) => handleOnChange(e.target.value)}                            
                             onFocus={(e) => (handleOnFocus(e.target.value, e.target.name))}
                             onBlur={(e) => handleOnBlur(e.target.name, e.target.value, index)}
                         />
                         {dropdown(unit, 'unit', item.unit, handleChange, index)}
                         </Row>
                         <LABEL>Description</LABEL>
                         <StyledTextArea
                             name="description"
                             type="text"
                             value={focused['description'] ? text : item.description}
                             onFocus={(e) => (handleOnFocus(e.target.value, e.target.name))}
                             onBlur={(e) => handleOnBlur(e.target.name, e.target.value, index)}
                             onChange={(e) => handleOnChange(e.target.value)}
                         />
                         <LABEL>Media</LABEL>
                         <Dropzone index={index} image={item?.media_url} setValues={setValues}/>
                     </WorkoutInputContent>
                //  </ColumnWrapper>
        )
     }

     const closeModal = () => {
        setTimeout(() => {
          setIsOpen(false);
        }, 0);
      };

    return(
    <Container>
        <ColumnWrapper>
                <StyledWorkoutInput
                    name="part_of_workout_name"
                    type="text"
                    value={nameOfWorkoutPart}
                    onChange={(e) => handleOnPartChange(e.target.value, e.target.name, 0)}
                />
                <ScrollView items={values} renderItem={(item, index) => 
                    item.part_of_workout_name ? null : (
                        <Content key={index} item={item} index={index}/>
                    )
                }/>
            <ButtonContainer>
                <CustomButton label={'Cancel'} onClick={() => handleCancel()} button={StyledSubmitButton}/>
                <CustomButton disabled={isSubmitDisabled} label={'submit'} onClick={() => handleSubmit(values)} button={StyledSubmitButton}/>
            </ButtonContainer>
        </ColumnWrapper>
        <RightBottomFloatingContainer>
            <AddButton onClick={addEmptyItem}/>
        </RightBottomFloatingContainer>
    </Container>
    )
}

export default EditWorkout;