import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth} from "../Utils/UseContext.jsx";
import { NavBar } from "./style/NavBar.styled.jsx";
//icon
import { CgProfile } from "react-icons/cg";


import '../components/style/Styles.css'

export const ProfileIcon = () => {
    const navigate = useNavigate();
    const {state} = useAuth();
    const show = state.isAuthenticated;

    // if(!show) return;

    return(
        <>
            {show && (
                <CgProfile size={30} onClick={() => navigate('/profile')} style={{cursor:'pointer', margin:10}}/>
            )}
        </>
    )
}

export const Nav = () => {
    const {state} = useAuth();
    const showNav = state.isAuthenticated;


    return(
        showNav && (
        <NavBar>
            <Link to="/" className="nav-link">Home</Link>
            <Link to="/workout/my" className="nav-link">Workout</Link>
            <Link to="/calendar" className="nav-link">Calendar</Link>
            {/* <Link to="/profile" className="nav-link">Profile</Link> */}
            {/* <button onClick={() => navTo('profile')}>LINKKI</button> */}
        </NavBar>
        )
    )
}
