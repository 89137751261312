
import { AddTokenToHeaders,AuthorizationHeaders, DateNow, SetToLocalStorage, delay, getFromLocalstorage} from "../Utils/Utils";
import {jwtDecode} from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const host_name = process.env.REACT_APP_HOST_API_KEY;
const ws_host = process.env.REACT_APP_WS_API_KEY;

const MAX_RETRIES = 10; // Maksimimäärä yrityksiä
let retries = 0


const CheckServer = async () => {
  try {
    const response = await fetch(`${host_name}/`,{
      method: 'GET',
      headers: {'Content-type':'application/json'}
    });
    const resp = await response.json();

    console.info("RESPONSE: ",resp.isUp)
    return resp.isUp;
  } catch (error) {
    console.log("ERROR: ", error)
    return false;
  }

}

function setLoginCookie(token, userKey) {
  const currentDate = DateNow();
  const expirationDate = new Date(currentDate);
  expirationDate.setFullYear(currentDate.getFullYear() + 1);

  const userData = { isAuthenticated: true, token: token, user: userKey };
  const cookieData = JSON.stringify(userData);
  document.cookie = `user=${cookieData}; expires=${expirationDate.toString()}; path=/`;
}

export const FetchPrivacyPolicy = async (setPrivacyPolicy) => {
  try {
    const response = await fetch(`${host_name}/privacypolicy`,{
      method: 'GET',
      headers: {'Content-type':'application/json'}
    });
    if(response.ok){
      const data = await response.json();
      
      setPrivacyPolicy(data)
    }else{
      if(response.status === 401){
        console.error("Response 401: ", response.status)
      }else{
        console.error("Error in response.", response.status)
      }
    }
  } catch (error) {
    console.error(error)
  }
}

export const HandleLogin = async (email, password, navigate, dispatch, setIsWrong) => {

  try{
    const response = await fetch(`${host_name}/post/login`,{
      method: 'POST',
      headers: {'Content-type':'application/json'},
      body: JSON.stringify({email, password})
    });

    if(response.ok){
      const {token} = await response.json();
      const decodedToken = jwtDecode(token);
      const userKey = decodedToken?.userId;
      // login(email, token)
      dispatch({ type: 'LOGIN', payload: token, user: userKey});
      setLoginCookie(token, userKey);

      navigate('/');
      // const service: string = userKey.toString();

      //sessionStorage.setItem('user', JSON.stringify({isAuthenticated: true, token: token, user: userKey}));
      
      // setToken(token);
      
    }else{
      setIsWrong(true);
      const message = await response.json();
      console.error("Server error occured", message)
    }
  }catch(error){
    console.error("ERROR: ", error)
  }
};

export const HandleRegisteration = async (firstname, lastname, email, password, dispatch, navigate, setEmailExist, checkValidation, setUndefinedErr) => {
  if(checkValidation()){
    try {
          const response = await fetch(`${host_name}/post/register`,{
              method: 'POST',
              headers: {'Content-type':'application/json'},
              body: JSON.stringify({email, firstname, lastname, password})
            });
            if(response.ok){
              const {token} = await response.json();
              if(!token){
                setEmailExist(true)
              }else{
              
               const decodedToken = jwtDecode(token);
               const userKey = decodedToken?.userId;
               // login(email, token)
               dispatch({ type: 'LOGIN', payload: token, user: userKey});
               setLoginCookie(token, userKey);
               navigate('/');
              }
            }else if(response.status === 409){
              setEmailExist(true)
            }
        
    } catch (error) {
        console.error("Error in registration: ", error)
    }
}else{
  setUndefinedErr(true)
}
}

export const UpdateExerciseDetails = async (id, value, name, part_id) => {
        const headers = await AddTokenToHeaders();

        try {
          const response = await fetch(`${host_name}/put/update_exercise_details`,{
            method: 'PUT',
            headers,
            // headers: {'Content-type':'application/json'},
            body: JSON.stringify({id, value, name}) // Tämän pitää olla sama kuin serveri puolella
          });
          if(response.ok){
            sessionStorage.removeItem('order-'+part_id);

            console.info("Details updated: ")
          }else{
            if(response.status === 401){
              console.error("Response: ", response.status);
            //    HandleExpiredToken().then(tok => {
            //      setToken(tok)
            //    });
            }else{
              console.error("Error in response.", response.status)
            }
          }
        } catch (error) {
          console.error("Error addin data to the database: ", error)
        }
      }

export const UpdateWorkout = async (formData, newworkout, setPersonalData, data, workoutId, workoutName, index, partId, navTo) => {
    const headers = await AuthorizationHeaders();
    const deleted = [];
    
     const updatedData = {
       ...data.data,
      //  [workoutId] : {...data.data[workoutId],  [workoutName]: [...data.data[workoutId][workoutName],  {[index] : {...data.data[workoutId][workoutName][index]}}]}
      [workoutId]: {
        ...data.data[workoutId],
        [workoutName]: data.data[workoutId][workoutName].map((item, i) => {
          if (i === index) {
            console.log("INDEX: ", index)
            // Korvaa vain kyseinen item
            return {
              ...item,
              ...newworkout, // Oletetaan että `newworkout` sisältää päivitetyt arvot
            };
          }
          return item;
        }),
      },
    };

     try {
        const response = await fetch(`${host_name}/put/update_exercise`,{
          method: 'PUT',
          headers,
          body: formData// Tämän pitää olla sama kuin serveri puolella
        });
        const data = await response.json();
        if(response.ok){
          const part_id = data?.part_id;
          sessionStorage.removeItem('order-'+part_id);
          
          setPersonalData({type: 'LOAD' , payload: data?.data});
          toast.success(data?.message)
          navTo(newworkout, partId)
        }else{
          if(response.status === 401){
            console.error("Response: ", response.status);
           
          }else{
            console.error("Error in response.", response.status)
           
          }
        }
     } catch (error) {
       console.error("Error addin data to the database: ", error)
       throw error;
   }
}

export const fetchPersonalData = async (setPersonalData) =>{    
        const headers = await AddTokenToHeaders();
        
        try {
          const response = await fetch(`${host_name}/load/workout/my`,{
            method: 'POST',
            headers: headers
          });
          if(response.ok){
            const data = await response.json();
            // SetToLocalStorage("myWorkout", data)
            await setPersonalData({type: 'LOAD' , payload: data?.data});
          }else{
            
            if(response.status === 400){
              console.error("expired token")
            }else{
              const localData = getFromLocalstorage("myWorkout");
              if (localData) {
                setPersonalData({ type: 'LOAD', payload: localData });
              }
            }
          }
        } catch (error) {
          const localData = getFromLocalstorage("myWorkout");
          if (localData) {
            setPersonalData({ type: 'LOAD', payload: localData });
          }
        }
      }

export const GetPublicData = (setPublicData) => {

  const ws = new WebSocket(`${ws_host}`);

  ws.onopen = () => {
      console.info("yhteys avattu")
    // Pyydä julkisia tietoja palvelimelta
     ws.send(JSON.stringify({ type: 'REQUEST_PUBLIC_DATA' }));
  };

  ws.onmessage = (event) => {
    const message = JSON.parse(event.data);
    if (message.type === 'PUBLIC_DATA') {
      setPublicData({type: 'LOAD' , payload: message.payload});
    }
  };

  ws.onclose = () => {
    console.info('Yhteys suljettu');
  };

  return () => {
    ws.close();
  };
}

export const GetEvaluationsData = async (starList, setEvaluationsData) =>{    
   try {
     const response = await fetch(`${host_name}/load/evaluations`,{
       method: 'GET',
       headers: {'Content-type':'application/json'}
     });
     if(response.ok){
       const data = await response.json();
      if(starList.length <= 0 && data.length > 0){ 
       data.forEach((i) => {
        starList.push(Number(i.stars))
       });
      }
       setEvaluationsData(data)
     }else{
       if(response.status === 401){
         console.error("Response 401: ", response.status)
       }else{
         console.error("Error in response.", response.status)
       }
     }
   } catch (error) {
     console.error(error)
   }
 }

export const GetWorkoutVisibility = async (workout_id) => {
  const headers = await AddTokenToHeaders();
 
  try {
    const response = await fetch(`${host_name}/load/workout/visibility`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({workout_id}) // Tämän pitää olla sama kuin serveri puolella
    });
    const result = await response.json();
    if(response.ok){
      const visibility = result?.visible;

      return visibility;
    }
  } catch (error) {
    console.error("ERROR: ", error)
    throw error;
  }
} 

export const GetCompletedWorkout = async (workout_id) => {
  const headers = await AddTokenToHeaders();
  try {
    const response = await fetch(`${host_name}/load/workout/completed`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({workout_id}) // Tämän pitää olla sama kuin serveri puolella
    });


    if(response.ok){
      const result = await response.json();

      return result;
    }
  } catch (error) {
    console.error(error);
    // throw error;
  }
}

export const GetWorkoutEvents = async () => {
  const headers = await AddTokenToHeaders();
  try {

    const response = await fetch(`${host_name}/load/workout/event`,{
      method: 'GET',
      headers: headers,
    });

     const result = await response.json();

     if(response.ok){
       return result;
     }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const VerifyEmail = async (email, verificationCode) => {
  try {
    const response = await fetch(`${host_name}/verify`,{
      method: 'POST',
      headers: {'Content-type':'application/json'},
      body: JSON.stringify({email, verificationCode}) // Tämän pitää olla sama kuin serveri puolella
    });
    const data = await response.json();
    const message = data?.message;
    const verified = data?.verified;

    return {message: message, verified: verified};
  } catch (error) {
    console.error("VERIFICATION ERROR: " ,error)
  }
}

export const ContactEmail = async (formData, setMessageSuccess) => {

    try {
      const response = await fetch(`${host_name}/post/contact`,{
        method: 'POST',
        headers: {'Content-type':'application/json'},
        body: JSON.stringify({formData}) // Tämän pitää olla sama kuin serveri puolella
      });
      const data = await response.json();
      if(response.ok){
        setMessageSuccess({message: data?.message, success: data?.success}) // success = true
      }else{
        setMessageSuccess({message: data?.message, success: data?.success}) // success = false
      }
    } catch (error) {
      console.error("contact ERROR: " ,error);
      throw error;
    }
}

export const PublishExercise  = async (formData) => {
  const headers = await AuthorizationHeaders();

 

//  let updatedVisibility;
//  visibilityBool ? updatedVisibility = 'private' : updatedVisibility = 'public';

  // const updatedData = {
  //   ...data,
  //   [nameofwholeexerciseid]: {...data[nameofwholeexerciseid], visibility: updatedVisibility}
  // } 

      try {
        const response = await fetch(`${host_name}/put/publish/workout`,{
          method: 'POST',
          headers,
          body: formData // Tämän pitää olla sama kuin serveri puolella
        });
        const data = await response.json();
        if(response.ok){
          console.info("status: ", response.status)
          // setPersonalData({type: 'LOAD' , payload: updatedData})
        }else{
          if(response.status === 401){
            console.error("Response: ", response.status);
            //  HandleExpiredToken().then(tok => {
            //    setToken(tok)
            //  });
          }else{
            console.error("Error in response.", response.status)
          }
        }
      } catch (error) {
        console.error("Error addin data to the database: ", error)
      }
}

export const ConfirmAccessRight = async (workout_id, expirydate) => {
  console.log(workout_id, " päättyy: ",expirydate)

  const headers = await AddTokenToHeaders();
  
   try {
     const response = await fetch(`${host_name}/post/exercise_accessright`,{
       method: 'POST',
       headers,
       body: JSON.stringify({workout_id, expirydate}) // Tämän pitää olla sama kuin serveri puolella
     });
     
     const result = await response.json();

     if(response.ok){
       console.log("Details updated: ")
       toast.success(result?.message)
       //Sulje ikkuna ja päivitä tiedot
     }else{
       if(response.status === 409){
         console.log("Response: ", result?.message);
         toast.info(result?.message)
         //  HandleExpiredToken().then(tok => {
         //    setToken(tok)
         //  });
       }else{
         console.log("Error in response.", response.status)
       }
     }
   } catch (error) {
     console.error("Error addin data to the database: ", error)
   }
  }

export const InsertCompletedIntoDb = async (key, completedData) => {
   const headers = await AddTokenToHeaders();
   const parsedData = JSON.parse(completedData)

   console.log("INSERT DATA: ", parsedData)

   const part_of_workout_name = parsedData.partname;
   const part_of_workout_id = parsedData.id;
   const exercise_array = parsedData.completed;
   const completion_rate = parsedData.completionRate;
   const date = parsedData.date;
   const start_time = parsedData.startTime;
   const completed_time = parsedData.endTime;
   const duration = completed_time - start_time;

   while(retries < MAX_RETRIES) {
   try {
     const response = await fetch(`${host_name}/post/completed/exercise`,{
       method: 'POST',
       headers,
       body: JSON.stringify({part_of_workout_name, part_of_workout_id, exercise_array, completion_rate, date, duration, completed_time, start_time}) // Tämän pitää olla sama kuin serveri puolella
     });

     if(response.ok){
       localStorage.removeItem(key)
       toast.success('Saved');
       retries = 0;
       return;
     }else{
       if(response.status === 401){
         console.error("Response 401");
         toast.warn('Unauthorized')
         
         return;
       }else{
         console.error("Error in response.", response.status)
         return;
       }
     }
   } catch (error) {
    //  console.error("Error addin data to the database: ", error);
    // console.error(`Attempt ${retries + 1} failed: ${error.message}`);
    retries++;

    toast.warn(`Attempt ${retries} failed: ${error.message}`,{
      autoClose: 8000,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
    })

     if (retries < MAX_RETRIES) { // MAX_RETRIES = 5
      console.info(`Retrying in 10 second... (${retries}/${MAX_RETRIES})`);
      // Aseta viive odottamista varten
      await delay(10000); // Esimerkiksi 10 sekuntia
      // Tarkista, onko palvelin palautunut
      const serverIsOnline = await CheckServer();
      if (!serverIsOnline) {
        // Yritä uudelleen tietokantaan lisäämistä
        // await InsertCompletedIntoDb(key, completedData);
        console.info('Server is still offline.');
        break;
      } 
      
    } else {
      console.info('Maximum retries reached. Aborting.');
      toast.error('Maximum retries reached. Aborting.')
    }
  }
   }
}

export const InsertNewWorkoutToDb = async (formData, navTo, setPersonalData, data) => {
  const headers = await AuthorizationHeaders();

  const updatedData = {
    ...data.data,
    ...formData
  }

    try {
       const response = await fetch(`${host_name}/post/new_exercise`,{
         method: 'POST',
         headers,
         body: formData // Tämän pitää olla sama kuin serveri puolella
       });
       const data = await response.json();

       if(response.ok){
          console.info("Tiedot lisätty: ", response.status)
          setPersonalData({type: 'LOAD' , payload: data?.data})
          navTo();
       }else{
         if(response.status === 401){
           console.error("Response: ", response.status);
           // HandleExpiredToken().then(tok => {
           //   setToken(tok)
           // });
         }else{
           console.error("Error in response.", response.status)
         }
       }
     } catch (error) {
       console.error("Error addin data to the database: ", error)
     }
}

export const InsertWorkoutEvent = async (workoutEvent, navto) => {
  const headers = await AddTokenToHeaders();

  try {
      const response = await fetch(`${host_name}/post/workout/event`,{
        method: 'POST',
        headers,
        body: JSON.stringify({workoutEvent}) // Tämän pitää olla sama kuin serveri puolella
      })
      const events = await response.json();
      if(response.ok){
       //ladataan tiedot uudelleen
       //navigoidaan
       navto();
      }
  } catch (error) {
    console.error("Event: ", error)
  }
}

export const HandleForgotPwdSubmit = async (email, checkValidation, setMessage) => {
  if(checkValidation){
    try {
          const response = await fetch(`${host_name}/forgot/password`,{
              method: 'POST',
              headers: {'Content-type':'application/json'},
              body: JSON.stringify({email})
            });

            const data = await response.json();
            const message = data?.message;
            setMessage(message);
            
    } catch (error) {
        console.error("Error responding password reset: ", error)
    }
}
}

export const HandleResetPassword = async (password, verificationData, checkValidation, setMessage) => {
  if(checkValidation){
    const verificationCode = verificationData?.code;
    const email = verificationData?.email;
    try {
          const response = await fetch(`${host_name}/reset/password`,{
              method: 'POST',
              headers: {'Content-type':'application/json'},
              body: JSON.stringify({email, verificationCode, password})
            });

            const data = await response.json();
            const message = data?.message;

            setMessage(data);
            
    } catch (error) {
        console.error("Error responding password reset: ", error)
    }
}
}

export const UpdateProfile = async (key, value) => {
  const headers = await AddTokenToHeaders();

  try {
    const response = await fetch(`${host_name}/patch/profile`,{
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify({key, value})
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const UpdateContactInfo = async (value) => {
  const headers = await AddTokenToHeaders();
  try {
      const response = await fetch(`${host_name}/put/address`,{
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({value})
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const UpdateUsername = async (value) => {
  const headers = await AddTokenToHeaders();

  try {
    const response = await fetch(`${host_name}/patch/username`,{
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify({value})
    });

    const data = await response.json();
  
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const UpdateUserExerciseOrder = async (value) => {
  const headers = await AddTokenToHeaders();
  
  try {
    const response = await fetch(`${host_name}/update/order`,{
      method: 'POST',
      headers,
      body: JSON.stringify({value}) // Tämän pitää olla sama kuin serveri puolella
    })
    const Resp = await response.json();
    if(response.ok){
      console.log("New order updated")
    }
} catch (error) {
  console.error("Update order: ", error)
}
}

export const UnpublishWorkout = async (workout_id) => {
  const headers = await AddTokenToHeaders();

  try {
    const response = await fetch(`${host_name}/patch/unpublish`,{
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify({workout_id})
    });

    const data = await response.json();
    
    if(response.ok){
      toast.success("Unpublished");
    }else{
      console.error("ERROR IN UNPUBLISHING: ", response.status)
    }
  } catch (error) {
    console.error(error);
    throw error;
  }

}

export const DeleteWorkout = async (workout_id, deleteItem) => {
  const headers = await AddTokenToHeaders();
  try {
      const response = await fetch(`${host_name}/delete/workout`,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify({workout_id})
    });

    const data = await response.json();
    if(response.ok){
      deleteItem();
      const isItem = localStorage.getItem('myWorkout');
      if(isItem){
        localStorage.setItem('myWorkout', deleteItem());
      }

      toast.success(data?.message  + new Date(data?.date).toLocaleDateString())
    }else{
      toast.warn(data?.message)
    }
    // return data;
  } catch (error) {
    console.error("error deleting workout: ", error);
    throw error;
  }
}

export const DeleteEvent = async (event_id) => {
  const headers = await AddTokenToHeaders();
  console.log("EVENT_ID: ", event_id)
  try {
      const response = await fetch(`${host_name}/delete/event`,{
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify({event_id})
    });

    const data = await response.json();
    if(response.ok){
      console.log("FETCHED DATA: ", data.data)
      return data.data;

    }
  } catch (error) {
    console.error("error deleting event: ", error);
    throw error;
  }
}


