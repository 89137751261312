import React, {useState, useEffect} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
//Components
import { ScrollView, CustomPopUp, Modal } from "../../../components/Components.jsx";
import { CustomButton, ExitButton, PublishButton } from "../../../components/Buttons.jsx";
import PublishDetails from "../Public/PublishDetails.jsx";
//API
import { PublishExercise, GetWorkoutVisibility, DeleteWorkout, UnpublishWorkout } from "../../../Utils/ApiRequests.jsx";
//Style
import { ExerciseContainer, WorkoutContent, WorkoutRow, Title } from "../StyledComponents.jsx";
import { StyledPublishButton } from "../../../components/style/Buttons.styled.jsx";
import { Row } from "../../../components/style/Row.styled.jsx";
import { DescriptionContainer } from "../../../components/style/Container.styled.jsx";
//icon
import { MdEditSquare, MdOutlinePublish } from "react-icons/md";
import { IoTrash, IoCloudOffline } from "react-icons/io5";
// utils
import { SetToLocalStorage, getFromLocalstorage} from "../../../Utils/Utils.jsx";
import { useData } from "../../../Utils/UseContext.jsx";
import { HasRole } from "../../../Utils/Role.jsx";


const WorkoutPartList = ({theme}) =>{
    const {personalData,setPersonalData} = useData();
    const [visibility, setVisibility] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const {values, accessRight, role} = location.state || {};
    let dataList = values?.list || [];

    useEffect(() => {
      const getVisibility = async () => {
        const id = values?.id;
        const visible = await GetWorkoutVisibility(id);
        setVisibility(visible);
      }
      getVisibility()
    },[])
    
    const closeModal = () => {
      setTimeout(() => {
        setIsOpen(false);
      }, 0);
    };
  
    const handleOnOfflineSave = (value) => {
      const local = getFromLocalstorage("myWorkout");
      const obj = local ? local : {};

      const id = value?.id;
      const name = value?.name;
      // console.log("VALUE TO STORAGE: ", personalData?.data[id][name])
      const list = personalData?.data[id][name]
      for(let i = 0; i < list.length; i++){
        const lis = Object.values(list[i])[0]
        for(let j = 0; j < lis.length; j++){
          if(lis[j].media_url){
            lis[j].media_url = "media";
          }
        }
      }
      
      Object.assign(obj, {[id]: personalData?.data[id]})

      SetToLocalStorage("myWorkout", obj)

    }

    const handleData = (data, name) => {
      const id = Object.keys(data)[0]
      const values = Object.values(data)[0]

      navigate(`/workout/my/${id}`, { state: { name, values, id } });
    }

    const handleEdit = (event, data, index) => {
      event.stopPropagation();
      const id = Object.keys(data)[0];
      const workoutId = values.id;
      const workoutName = values.name

      navigate(`/workout/edit/${id}`, { state: { data, workoutName, workoutId, index } });
    }

    const handleOnDelete = async (id) => {

        const deleteItem = () => {
            delete personalData?.data[id];
            const newItems = personalData?.data;

          return JSON.stringify(newItems);
        }
     
        await DeleteWorkout(id, deleteItem)
        navigate('/workout/my')
    }

    const part = Object.values(dataList).map((data, index) => {
        let cardListoflist = Object.values(data)
        let cardlist = cardListoflist[0]
        
        const name = cardlist.map((d, index) => {
            //console.log("DATA WHICH NEED TO BE SENT: ",index, " : ", d)
             if(d.part_of_workout_name != undefined){
                return (<p key={index} style={{fontSize:20}}>{d.part_of_workout_name}</p>)
            }
        })
        
        return (
            <WorkoutRow onClick={() => handleData(data, values.name)} height={2.3} columns={'1fr 1fr'}>
                <WorkoutContent align={'flex-start'} >
                    {name}
                </WorkoutContent>
                <WorkoutContent align={'flex-end'}>
                  <MdEditSquare onClick={(event) => handleEdit(event, data, index)} color={'white'} size={20}/>
                </WorkoutContent>
            </WorkoutRow>
        )
     })
     
     const HeaderButtons = () => {
      const [hasRole, setHasRole] = useState(false);
      const [isPopUpOpen, setIsPopUpOpen] = useState(false);
      const [content, setContent] = useState("");
     
      useEffect(() => {
        const checkRole = async () => {
            const result = await HasRole("identified");
            setHasRole(result);
        };
        
        checkRole();
    }, []);

    const ConfirmationPopUp = (id) => {
      const buttons = [
        // { label: 'Ei', onClick: handleCancel, color: Colors.red},
        { label: 'Yes', onClick:() =>  handleOnDelete(id)},
        { label: 'No', onClick: () => setIsPopUpOpen(false)}
      ];

      const message =`Are you sure you want to delete the exercise "${values?.name}"? \n`
      setContent({message: message, buttons: buttons})
      setIsPopUpOpen(!isPopUpOpen);

    }

      return (
        <>
          <IoTrash onClick={() => ConfirmationPopUp(values?.id)} size={20} style={{cursor: 'pointer'}}/>  
          {hasRole && (
            <>
            {accessRight === 'owner' && (
              <CustomButton
                icon={<MdOutlinePublish size={20}/>}
                label={visibility ? `unpublish` : `Publish`}
                button={StyledPublishButton}
                onClick={visibility ? () => UnpublishWorkout(values?.id) : () => setIsOpen(!isOpen)}
                />
            )}
              <CustomButton 
                button={StyledPublishButton}
                onClick={() => handleOnOfflineSave(values)}
                label={<span><IoCloudOffline />{" Save for offline use"}</span>}
              />
            </>
          )}
          <ExitButton onClick={() => navigate('/workout/my')} color={'white'} label={'X'}/>
          <CustomPopUp content = {content} isOpen={isPopUpOpen} setIsOpen={() => setIsPopUpOpen(!isPopUpOpen)} div={DescriptionContainer}/>
        </>
      )
     }

    return (
      <>
          <ExerciseContainer justify={'none'}>
            <Row padding={'0px'}>
                <HeaderButtons />
            </Row>
            <Row padding={'0px 0px 10px 0px'}>
              <Title>{values?.name}</Title>
            </Row>
            <ScrollView items = {part} renderItem={(item) => <span>{item}</span>}/>
          </ExerciseContainer>
          <Modal
            isOpen={isOpen}
            onClose={() => closeModal()}
            children = { <PublishDetails data={values} accessRole={accessRight} theme={theme} close={closeModal}/>
            }
          />
      </>
    )
  }

  export default WorkoutPartList;