import React, {useState, useEffect} from "react";
import { useParams, useNavigate } from 'react-router-dom';

import { useData } from "../../../Utils/UseContext";
import { GetPublicData, ConfirmAccessRight } from "../../../Utils/ApiRequests";
import { ExpiryDate, ScrollView, CustomPopUp } from "../../../components/Components";
import { StyledSubmitButton } from "../../../components/style/Buttons.styled";
import { DescriptionContainer, Container, PublishDetailsContainer, ButtonContainer, Container100 } from "../../../components/style/Container.styled";
import { Row, Column } from "../../../components/style/Row.styled";
import { WorkoutMedia } from "../StyledComponents";
import { DateNow, IncreaseDays } from "../../../Utils/Utils";



const PreviewScreen = ({theme}) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const {publicData, setPublicData} = useData(null);
    const [selectedItem, setSelectedItem] = useState(null); // Tila valitulle itemille
    const [isVisible, setIsVisible] = useState(false);
    const [popupContent, setPopupContent] = useState("");

    useEffect(() => {
      GetPublicData(setPublicData);        
    },[])

    useEffect(()=> {
        if (publicData.data && id) {
            const foundItem = publicData.data.find(item => item.workout_id === id); // Suodata data listasta id:n perusteella
            setSelectedItem(foundItem); // Aseta löydetty item tilaansa
          }
    },[publicData?.data, id])

    // const message = `Haluatko ostaa harjoitusohjelman: ${title}? \nHinta: ${price} \nAlkaen:${DateToString(DateNow())}  \nPäättyen:${DateToString(ExpiryDate(Number(expiredIn)))}`
  
      const handleCancel = () => {
        setIsVisible(false)
        // closeModal()
      }
  
     const handleConfirm = async () => {
       // tämän pitäisi suorittaa maksu operaatio ja kun operaatio suoritettu annetaan oikeus katsella harjoitusta.
       //confirmAccessRight(id,ExpiryDate(Number(expiredIn)))
       await ConfirmAccessRight(id, ExpiryDate(Number(selectedItem?.expired_time)))
       // console.log("CONFIRMED: ",id)
       // setIsVisible(!isVisible)
       handleCancel()
       // setIsOpen(false)
     }

    const returnCurrency = (currency) => {
      switch (currency) {
          case 'euro':
              return '€';
          case 'dollar':
              return '$'
          default:
              return '€';
      }
  }

   const ConfirmationPopUp = () => {
     const buttons = [
       { label: 'No', onClick: handleCancel , bstyle: StyledSubmitButton},
       { label: 'Yes', onClick: handleConfirm, bstyle: StyledSubmitButton}
     ];

    const message =`Are you sure you want to buy ${selectedItem?.workout_name} for ${selectedItem?.price}${returnCurrency(selectedItem?.currency)}?`

     setPopupContent({message: message, buttons: buttons});
     setIsVisible(!isVisible);
   }

    return (
       <Container direction={'column'}>
        <Container100>
           <Row padding={'0'} style={{background: theme.colors.body}}>
             <WorkoutMedia src={selectedItem?.media_url} type="video" />
               <h3>{selectedItem?.workout_name}</h3>
           </Row>
           <Column height={'17em'}>
             <h3>Description</h3>
             <Row align={'flex-start'} padding={'0'} style={{background: theme.colors.body}} scroll={'auto'}>
                 {selectedItem?.description}
             </Row>
           </Column>

           <PublishDetailsContainer>
             <Column width={'100%'}>
               <h5 style={{marginBottom: 5}}>Validity period</h5>
               <p>{DateNow().toLocaleDateString()} - {IncreaseDays(selectedItem?.expired_time)}</p>
               <p>The workout can be viewed for {selectedItem?.expired_time} days.</p>
             </Column>
             <Row padding={'0'} margin={'10px 0 0 0'}>
               <Column>
               <h5 style={{marginBottom: 5}}>The workout includes {selectedItem?.part_names?.length} parts</h5>
               {(selectedItem?.display_parts && selectedItem?.part_names?.length > 0)&& (
                 <ScrollView items={selectedItem?.part_names} renderItem={(item) => <p style={{textTransform: 'uppercase'}}>{item}</p>}/>
                 )
               }
               </Column>
               <Column>
                 <h5 style={{marginBottom: 5}}>Price</h5>
                 {selectedItem?.price === 0 ? <h3>FREE</h3> : <h3>{selectedItem?.price} {returnCurrency(selectedItem?.currency)}</h3>}
               </Column>
             </Row>
           </PublishDetailsContainer>
           <ButtonContainer justify={'end'}>
             <StyledSubmitButton onClick={() => navigate('/workout/public')} width={'100px'} style={{marginRight: 20}}>
               Cancel
             </StyledSubmitButton>
             <StyledSubmitButton onClick={() => ConfirmationPopUp()} width={'100px'}>
               Buy
             </StyledSubmitButton>
           </ButtonContainer>
           <CustomPopUp content={popupContent} isOpen ={isVisible} setIsOpen={() => setIsVisible(!isVisible)} div={DescriptionContainer} />
           </Container100>
       </Container>
    )
  }

  export default PreviewScreen;