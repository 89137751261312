
import styled from 'styled-components';
import { Colors } from '../../components/style/Colors.jsx';

export const WorkoutContainer = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // min-height:85vh;
  margin-top: 20px;
  // margin: 10px 20px auto auto;
  // border: 2px solid ${({theme}) => theme.colors.border};
  
  @media screen and (min-width: 500px) { //desktop screen
    margin: 20px auto;
    max-width: 60em;
    
  }
`

export const ExerciseContainer = styled.div`
  display:flex;
  flex-direction: column;
  aling-items: center;
  justify-content: ${(props) => props.justify || 'space-between'};
  // max-width: 40em;
  height:94vh;
  overflow: hidden;
  padding: 20px 0 2.4em 0;
  // background: ${({theme}) => theme.colors.textBg};

  @media screen and (min-width: 500px) and (min-height: 500px) {
    margin: 25px auto auto auto;
    //position:relative;
    width:100%;
    height: 85vh;
    border-radius: 20px;
    padding: 0 1em 0 1em;
  }
`

export const ExerciseContent = styled.div`
  overflow: auto;
`

export const WorkoutButtons = styled.div`
  display: flex;
  aling-items: center;
  justify-content: space-around;
  // width: 35em;
  margin: auto;

  button{
    border-radius: 20px;
    // width: 50%;
    padding: 5px;
    // border: 2px solid ${({theme}) => theme.colors.border};
    background: none;
    // ${({theme}) => theme.colors.body};
    color: ${({theme}) => theme.colors.text};
    margin: 10px;
    
    &:hover {
      background: rgba(128, 128, 128, 0.402);
      border: none; 
    }

  }
`

export const WorkoutRow = styled.div`
  //width: 100%;
  min-height: 80px;
  height: ${(props) => props.height || 'fit-content'};
  display: grid;
  grid-template-columns: ${(props) => props.columns || '1fr 1fr 1fr 1fr 0.5fr'};
  column-gap:1px;  
  // flex-direction: row;
  //justify-content: space-between;
  // border-bottom: 2px solid ${({theme}) => theme.colors.border};
  border-radius: 10px;
  margin-bottom: 1em;
  // margin-top: 5px;
  padding: 5px 10px 5px 10px;
  background: ${({theme}) => theme.colors.textBg};


  & > p{
    color: red;
  }
`;

export const WorkoutMedia = styled.div`
    background-image: url(${(props) => props.src});
    background-size: contain; /* Säädä kuvan kokoa */
    background-repeat: no-repeat; /* Estää kuvaa toistumasta */
    background-position: center;
    border: 1px solid ${({theme}) => theme.colors.border};
    border-radius: 6px;
    width: 80px;
    height: 80px;
`

export const WorkoutContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justify || 'center'};
  overflow: hidden;
  align-items: ${(props) => props.align || 'center'};
  // margin: 0 0.2em 0.3em 0.2em;
  padding: 0 5px 0 5px;
  color:${({theme}) => theme.colors.text};

  & > p{
    font-size: ${(props) => props.font ||14}px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  & > h4{
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  @media screen and (min-width: 600px) { // BIG screen
 
      & > p{
        font-size: ${(props) => props.font || 16}px;
      }

      & > h4{
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
  }

`;

export const WorkoutDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 0.7em;
  padding: 0.1em;
  // background: ${Colors.transparentgBlue};
  background: red;



  & > p {
    margin: ${(props) => props.margin}; // string
    // color: ${Colors.lightMode.TEXT_COLOR};
    font-weight: 700;
  }

  & > input {
    // color: ${Colors.lightMode.TEXT_COLOR};
    font-weight: 600;
  }
`
export const WorkoutPartContainer = styled.div`
position:relative;
display: flex;
justify-content:center;
align-items: ${(props) => props.align || 'center'};
flex-direction: row;
width: 100%;
border-radius: 0.7em;
// padding: 0.1em;
// background: ${Colors.transparentgBlue};
`

export const WorkoutInputContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
// overflow: hidden;
align-items: ${(props) => props.align || 'center'};
// margin-left: 0.5em;
// color:${({theme}) => theme.colors.text};
// background: ${({theme}) => theme.colors.textBg};
border-radius: 6px;
padding: 5px;
width: 100vw;

.amount_inside_div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: ${(props) => props.align || 'center'};
  width: 100vw;

}

 @media screen and (min-width: 481px) and (min-height: 500px) {
   width: 50em;

 }


  @media screen and (min-width: 481px) and (min-height: 500px) {

    .content_div{
      display: flex;
    }

    .amounts_div {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .amount_inside_div {
      max-width: 50%;
    }

  }


`
export const WorkoutInputContent = styled.div`
  position: ${({position}) => position && (position)};
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  justify-content: ${({justify}) => justify && (justify) };
  // overflow: hidden;
  align-items: ${(props) => props.align || 'flex-start'};
  width: ${({width}) => width || '100vw'};
  margin: ${({margin}) => margin || '0 0 0.5em 0'};
  padding: 0 0 10px 0;
  color:${({theme}) => theme.colors.text};

  @media screen and (max-width: 481px) { // mobile

    border-bottom: ${({border}) => border || 'none'} ${({theme}) => theme.colors.border};
  }


  @media screen and (min-width: 481px) and (min-height: 500px) { // desktop
    max-width: 100%;
    border: ${({border}) => border || 'none'} ${({theme}) => theme.colors.border};
    border-radius: 20px;
  }

`

export const CreatorName = styled.p`
  font-size: 5px;
  font-weight: 400;
  margin: 0;
  text-aling: flex-start;
 `;

export const Title = styled.h3`
  // font-size: 18px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  color: ${({theme}) => theme.colors.text};
`;

export const Details = styled.p`
  margin: 0;
`;

