import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HeaderContent, StyledHeader } from "./style/Header.styled.jsx";
import { useTimer, useAuth } from "../Utils/UseContext.jsx";
import Logo from './style/images/Logo.png'
import { MdArrowBackIos } from "react-icons/md";
import { LoginIcon, LogoutIcon } from "./AuthIcon.jsx"; 
import { ProfileIcon } from "./Nav.jsx";



const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {dispatch} = useAuth();
    const {isTimerRunning} = useTimer();

    const navTo = () => {
        if(!isTimerRunning){
            navigate('/');
        }
    }

    const back = () => {
        navigate(-1);
    }

    return(
        <StyledHeader>
            <HeaderContent justify={'flex-start'}>
                <MdArrowBackIos onClick={() => back()} size={20}/>
            </HeaderContent>
            <HeaderContent>
                <img onClick={() => navTo()} style={{height:'2.5em', cursor:'pointer'}}src={Logo} alt="Logo" />
            </HeaderContent>
            <HeaderContent justify={'flex-end'}>
                {location.pathname === '/profile'? <LogoutIcon onClick={() => dispatch({ type: 'LOGOUT'})} /> : <ProfileIcon />}
                <LoginIcon />
            </HeaderContent>
        </StyledHeader>
    )
}

export default Header;