import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams} from 'react-router-dom';

// API
import { HandleForgotPwdSubmit, HandleResetPassword } from '../Utils/ApiRequests';
//style
import { AuthContainer, FormContainer } from '../components/style/Container.styled';
import { LogoWrapper } from '../components/style/Logo.styled';
import { StyledSubmitButton } from '../components/style/Buttons.styled';
import { StyledCustomInput } from '../components/style/CustomInput.styled.jsx';
//img
import Logo from '../components/style/images/Logo.png'
//components
import { CustomButton} from '../components/Buttons';
import { checkPwdIsSame, checkPwdLen, isPwdStrong } from './AuthUtils.jsx';



function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    setEmail("");
    setIsValidEmail(false)
  },[message])

  const validateEmail = (text) => {
    const emailRegex = /\S+@\S+\.\S+/;
    const isValid = emailRegex.test(text);
    setIsValidEmail(isValid);
    setEmail(text);
    };


  return (
    <AuthContainer>
        <LogoWrapper src={Logo} />
        <div>
        <FormContainer>
            <h3>Forgot password</h3>
            <StyledCustomInput          
              id="email"
              placeholder="email"
              value={email}
              onChange={e => validateEmail(e.target.value)}
              type={'email'}
            />
            <p>{message}</p>
            <CustomButton
              button={StyledSubmitButton}
              onClick={() => HandleForgotPwdSubmit(email, isValidEmail, setMessage)}
              label={'Submit'}
              disabled={!isValidEmail}/>
        </FormContainer>
        </div>
    </AuthContainer>
  );
}

function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [verificationData, setVerificationData] = useState({email: null, code: null});
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [message, setMessage] = useState(null);
  const [counter, setCounter] = useState(null);

  useEffect(() => {
    const code = searchParams.get('code');
    const email = searchParams.get('email');
    if (code && email) {
      // Aseta varmistuskoodi tilaan
      const verificationObj = {email: email, code: code}
      setVerificationData(verificationObj);
      // Voit tässä vaiheessa lähettää varmistuskoodin palvelimelle ja käsitellä varmistuksen
    }
  }, [searchParams]);

  useEffect(() => {
    let intervalId;
    if (counter > 0) {
      intervalId = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
    }else if(counter === 0){
        navigate('/login')
    }

    // Poista ajastin, kun komponentti puretaan (unmounted)
     return () => {
      clearInterval(intervalId);
     };
  }, [counter]);

  useEffect(() => {
    if(message?.changed){
      setCounter(10);
    }
    setPassword("");
    setPassword2("");
  }, [message])


  const checkValidation = () => {
    if(password < 3 || password2 < 3) return false; 
    if( checkPwdLen(password) && checkPwdIsSame(password, password2) && isPwdStrong(password)){
        return true;
    }else{
        return false;
    }
}

  return (
    <AuthContainer>
        <LogoWrapper src={Logo} />
        <div>
        <FormContainer>
            <h3>Reset password</h3>
            <StyledCustomInput          
              id="password"
              placeholder="Password..."
              value={password}
              onChange={e => setPassword(e.target.value)}
              type={'password'}
            />
            <StyledCustomInput          
              id="password2"
              placeholder="Password again..."
              value={password2}
              onChange={e => setPassword2(e.target.value)}
              type={'password'}
            />
            <p>{message?.message}</p>
            <CustomButton
              button={StyledSubmitButton}
              onClick={() => HandleResetPassword(password, verificationData, checkValidation, setMessage)}
              label={'Reset password'}
              disabled={!checkValidation()}/>
              {counter > 0 && (
                <p>Siirrytään automaattisesti <span style={{cursor:'pointer'}} onClick={() => navigate('/login')}>pois</span> {counter} sekunnin kuluttua.</p>
              )}
        </FormContainer>
        </div> 
    </AuthContainer>
  );
}

export {ForgotPassword, ResetPassword};
