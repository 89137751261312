import React, { useEffect, useState } from "react";
//components
import {useAuth} from "../Utils/UseContext.jsx";
//API
import { UpdateProfile, UpdateUsername, UpdateContactInfo } from "../Utils/ApiRequests.jsx";
//Utils
import { GetdecodeToken, DateToString } from "../Utils/Utils.jsx";
import { encryptData, decryptData } from "../Utils/encryption.js";
//Button
import { LogoutIcon } from "../components/AuthIcon.jsx";
//style
import { CustomDatepicker } from "../components/style/Date.styled.jsx";
import { Row } from "../components/style/Row.styled.jsx";
import { ProfileContent, ProfileContainer, RoundImgContainer, StyledUserDetails, ContactDetails, NativeSelect, OverflowTextContent } from "../components/style/Container.styled.jsx";
import '../components/style/Styles.css'
import 'react-datepicker/dist/react-datepicker.css';
//icon
import Logo from '../components/style/images/Logo.png'
import { MdOutlineEmail, MdPersonOutline, MdOutlineCake, MdOutlineKey, MdLocationOn, MdOutlineSmartphone, MdOutlineTransgender, MdAdd, MdEditSquare, MdDone } from "react-icons/md";


const Datepicker = (props) => {
    return(
        <CustomDatepicker
        selected={props.birthday}
        onChange={props.handleDateChange}
        dateFormat="dd.MM.yyyy"
        className="custom-datepicker"
        calendarClassName="custom-calendar"
        theme={props.theme}
      />
    )
}

const AddUsername = ({theme, profile, setProfile}) => {
    const [username, setUsername] = useState("");

    const handleUsernameChange = async () => {
        const value = await UpdateUsername(username)
        const updatedProfile = { ...profile, username: value?.username };
        setProfile(updatedProfile);
        sessionStorage.setItem('profile', JSON.stringify(updatedProfile));
    }

    return(
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor:'pointer'}}>
            <input 
                placeholder={'username'}
                value={username}
                onChange={e => setUsername(e.target.value)}
                style={{border: 'none', background:`${theme.colors.body}`, color:`${theme.colors.text}`, width: '100%'}}/>
            <MdAdd size={20} onClick={handleUsernameChange}/>
        </div>
    )
}

const UserDetails = ({theme, profile, setProfile}) => {
    const [inputDisabled, setInputDisabled] = useState(true)
    const [openDropdown, setOpenDropdown] = useState(false)
    const [selectedOption, setSelectedOption] = useState();
    const [options, setOptions] = useState([
        {value: 'E', label: 'Empty'},
        { value: 'M', label: 'Men' },
        { value: 'W', label: 'Women' },
        { value: 'O', label: 'Other' },
        ]);

    
    useEffect(() => {
        setSelectedOption(profile?.gendre);
    },[profile])
   

    const dropdown = () => {
        return(
            <NativeSelect value={selectedOption} onChange={handleChange} onClick={() => setOpenDropdown(!openDropdown)}>
                {options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </NativeSelect>
        )
    }

    const handleChange = async (e) => {
        const value = e.target.value;
        setSelectedOption(value);
        const updatedProfile = {...profile, gendre: value}
        await UpdateProfile('gendre',value);
        setProfile(updatedProfile);
        sessionStorage.setItem('profile', JSON.stringify(updatedProfile))
        setOpenDropdown(false);
      };

    const handleDateChange = async (date) => {
        console.log(date)
        setProfile(prev => ({...prev, birthday: date})); 
        //Tallennetaan tieotkantaan
    }

    const handleEdit = async () => {
        if(inputDisabled){
            setInputDisabled(!inputDisabled);
        }else{
            await UpdateProfile('bday',profile?.birthday);
            const encrypt = encryptData(profile);
            sessionStorage.setItem('profile',encrypt);
            setInputDisabled(!inputDisabled);
        }
    }
  
    return(
        <>
            <StyledUserDetails>
                <OverflowTextContent>
                <div style={{cursor: 'pointer'}} onClick={handleEdit}>{inputDisabled ? <MdEditSquare size={17} color={theme.colors.text} /> : <MdDone size={17} color={theme.colors.text} />}</div>
                <span style={{cursor: 'not-allowed'}}><MdPersonOutline size={20}/> {profile?.firstname} {profile?.lastname}</span>
                <span style={{marginLeft: '1.59em', cursor: 'not-allowed'}}>{profile?.username ? `${profile?.username}` : <AddUsername profile={profile} setProfile={setProfile} theme={theme}/>}</span>
                <span style={{cursor: 'not-allowed'}}> <MdOutlineEmail size={20}/> {profile?.email}</span>
                <span style={{cursor: 'not-allowed'}}><MdOutlineKey size={20}/> {profile?.role}</span>
                <span style={{cursor: inputDisabled && ('not-allowed')}}><MdOutlineCake size={20}/> {profile?.birthday && inputDisabled ? DateToString(new Date(profile?.birthday), true) : <Datepicker handleDateChange={handleDateChange} birthday={profile?.birthday} theme={theme} />}</span>
                <span style={{display: 'flex', alignItems: 'center', padding:2 }}>
                    <MdOutlineTransgender size={24} />
                    {dropdown()}
                </span>
                </OverflowTextContent>
            </StyledUserDetails>
        </>
    )
}

const ContactInfo = ({theme, contactInfo}) => {
    const [inputDisabled, setInputDisabled] = useState(true)
    const [contactValue, setContactValue] = useState()

    useEffect(() => {
        setContactValue({
            address: contactInfo?.address ? contactInfo?.address :  "",
            postcode: contactInfo?.postcode || "",
            city: contactInfo?.city || "",
            country: contactInfo?.country || "",
            phone: contactInfo?.phone || ""
        })
    },[contactInfo])
    
    const Input = ({name, placeholder, val}) => {
        const [text, setText] = useState("")
        const [focused, setFocused] = useState({"": false})

        const handleOnFocus = (value, key) => {
            setFocused({[key]: true});
            setText(value);
          }

        const handleOnChange = (value) => {
            setText(value)
        }

        return (
            <input 
            disabled={inputDisabled}
            name={name}
            placeholder={placeholder}
            value={focused[name] ? text : val}
            onFocus={() => (handleOnFocus(val, name))}
            onChange={e => handleOnChange(e.target.value)}
            onBlur={() => handleOnBlur(name, text)}
            style={{border: 'none', background:`${theme.colors.body}`, color:`${theme.colors.text}`, width: '30%'}}/>
        )
    }

    const handleOnBlur = (name, value) => {
        const newContactInfo = {...contactValue, [name]: value}
        setContactValue(newContactInfo);
    }

    let address = contactInfo?.address && inputDisabled ? contactInfo?.address : <Input name={"address"} placeholder={"Address"} val={contactValue?.address}/>
    let postcode = contactInfo?.postcode && inputDisabled ? contactInfo?.postcode : <Input name={"postcode"} placeholder={"Postcode"} val={contactValue?.postcode}/>
    let city = contactInfo?.city && inputDisabled ? contactInfo?.city : <Input name={"city"} placeholder={"City"} val={contactValue?.city}/>
    let country = contactInfo?.country && inputDisabled ? contactInfo?.country : <Input name={"country"} placeholder={"Country"} val={contactValue?.country}/>
    let phone = contactInfo?.phone && inputDisabled ? contactInfo?.phone : <Input name={"phone"} placeholder={"Phone"} val={contactValue?.phone}/>

    const handleEdit = async () => {
        if(inputDisabled){
            setInputDisabled(!inputDisabled);
        }else{
            await UpdateContactInfo(contactValue)
            const encrypt = encryptData(contactValue);
            sessionStorage.setItem('contact',encrypt);
            setInputDisabled(!inputDisabled);
        }
    }

    return (
    <ContactDetails>
            <Row justify={'flex-start'}>
            <h4 style={{marginLeft: 5, marginRight: 5}}>Contact</h4>
            <div style={{cursor: 'pointer'}} onClick={handleEdit}>{inputDisabled ? <MdEditSquare size={17} color={theme.colors.text} /> : <MdDone size={17} color={theme.colors.text} />}</div>
            </Row>
            <span><MdLocationOn size={20} /> {address} , {postcode}, {city} </span>
            <p style={{marginLeft: 25}}>{country}</p>
            <span><MdOutlineSmartphone size={20}/> {phone}</span>
    </ContactDetails>
    )
}

const Profile = ({theme}) => {
    const {dispatch} = useAuth();
    const [profile, setProfile] = useState(null);
    const [contactInfo, setContactInfo] = useState(null);

    const getUser = async () => {
        const result = await GetdecodeToken();
        return result;
    }

    const setToProfile = async () => {
        const storedProfile = sessionStorage.getItem('profile');
        if (storedProfile) {
            const decrypt = decryptData(storedProfile);
            setProfile(JSON.parse(decrypt));
        } else {
        const user = await getUser();
            const newObj = {
                firstname: user?.firstname,
                lastname: user?.lastname,
                email: user?.email,
                username: user?.username,
                role: user?.role,
                gendre: user?.profile?.gendre,
                birthday: user?.profile?.bday
            }
            setProfile(newObj);
            // store crypted data to sessionStorage
            const encrypt = encryptData(newObj);
            sessionStorage.setItem('profile',encrypt);
        }
    }

    const setToContactInfo= async () => {
        const storedContactInfo = sessionStorage.getItem('contact');
        const user = await getUser();
        if (storedContactInfo) {
            const decrypt = decryptData(storedContactInfo);
            setContactInfo(JSON.parse(decrypt));
        } else if(user?.address !== undefined) {
            const newObj = {
                address: user?.address?.address,
                postcode: user?.address?.postcode,
                city: user?.address?.city,
                country: user?.address?.country,
                phone: user?.address?.phone
            }

            setContactInfo(newObj);
            // store crypted data to sessionStorage
            const encrypt = encryptData(newObj);
            sessionStorage.setItem('contact',encrypt);
        }else{
            return;
        }
    }

    useEffect(() => {
        setToProfile();
        setToContactInfo();
    },[])

    return(
        <>
        {/* <LogoutIcon onClick={() => dispatch({ type: 'LOGOUT'})} /> */}
            <ProfileContainer>
                <ProfileContent>
                    <RoundImgContainer src={Logo} />
                    {/* <div> */}
                        <UserDetails profile={profile && (profile)} setProfile={setProfile} theme={theme}/>
                    {/* </div> */}
                    <ContactInfo contactInfo={contactInfo && (contactInfo)} theme={theme}/>
                </ProfileContent>
                {/* <ProfileContent></ProfileContent> */}
            </ProfileContainer>
        </>
    )
}

export default Profile;