import React, { useDebugValue, useEffect, useState } from "react";
// Components
import { Dropzone } from "../Modify_workout/NewWorkout.jsx";
import { StyledTextArea, StyledCustomInput } from "../../../components/style/CustomInput.styled";
import { CustomPopUp } from "../../../components/Components.jsx";
// Style
import { Container, DescriptionContainer, ButtonContainer } from "../../../components/style/Container.styled";
import { Row, Column } from "../../../components/style/Row.styled";
import { StyledSubmitButton } from "../../../components/style/Buttons.styled.jsx";
//Icon
import { IoInformationCircleOutline } from "react-icons/io5";
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";
//button
import { CustomButton, PublishButton } from "../../../components/Buttons.jsx";
//API
import { PublishExercise } from "../../../Utils/ApiRequests.jsx";



const PublishDetails = ({data, accessRole, theme, close}) => {
    const [newData, setNewData] = useState([]);
    const [partnames, setPartnames] = useState([]);
    const [expires, setExpires] = useState(30);
    const [price, setPrice] = useState(10);
    const [showInfo, setShowInfo] = useState(false);
    const [termReaded, setTermReaded] = useState(false);
    const [showPart, setShowPart] = useState(true);
    const currency = "euro";

    useEffect(() => {
        data.description = "";
        data.media = null;
        setNewData(data);
    },[data])

    const description = "Write a description of the training program as descriptive as possible, a good description increases interest." +
                        " \n\n- Set a realistic price and tell how long the exercise will be available after purchase. " +
                        " \n\n- Also choose whether to display only the number of exercise parts or also the titles." +
                        " \n\nThe published material must not contain racist, prohibited or other offensive content." +
                        " Violation of the terms may result in the deletion of the account." +
                        " \n\nThe company charges 12% per purchase, however, so that the minimum charge is €1." +
                        " There is no charge for free content, however," +
                        " The company has the right to add advertisements to free products." +
                        " \nWe reserve the right to make changes."

    const ShowTerms = () => {
        setShowInfo(!showInfo);
        setTermReaded(true);
    }

    const handleOnPublish = async () => {
        const dataObj = {id: newData.id, description: newData?.description, price:price, expires:expires, showPart:showPart, partnames:partnames, currency: currency};
        const formData = new FormData();
        formData.append('data', JSON.stringify(dataObj)); // Lisätään muu data
        formData.append(`media`,newData.file);

        await PublishExercise(formData);
        // const visible = await GetWorkoutVisibility(id);
        // setVisibility(visible)
        close();
    }

    const returnCurrency = (currency) => {
        switch (currency) {
            case 'euro':
                return '€';
            case 'dollar':
                return '$'
            default:
                return '€';
        }
    }

    return(
        <Container direction={'column'} background={theme.colors.body}>
            <Row justify={'start'}>
                <Column>
                    <Row margin={'0 0 30px 0'} padding={'0'}>
                        <h3 style={{marginRight: 10}}>{data.name}</h3>
                        <IoInformationCircleOutline onClick={() => setShowInfo(!showInfo)}size={30} color={theme.colors.text}/>
                    </Row>
                    <Dropzone 
                        content={newData}
                        setContent={setNewData}
                        placeholder={'Image'}
                        accept={{
                            'image/*': ['.jpeg', '.jpg', '.png', '.gif']
                        }}/>
                </Column>
                <Column justify={'end'}>
                    <Row width={'fit-content'}>
                        <p>{"Price: "}</p>
                        <StyledCustomInput 
                            value={price} 
                            onChange={(e) => setPrice(e.target.value)}
                            text_decoration={'underline'} 
                            border={'none'} 
                            text_align={'center'} 
                            width={30}
                            margin={'0'}/>
                        <p>{returnCurrency(currency)}</p>
                    </Row>
                    <Row width={'100%'}>
                        <p>{`Expires in `}</p>
                        <StyledCustomInput 
                            value={expires}
                            onChange={(e) => setExpires(e.target.value)}
                            text_decoration={'underline'} 
                            border={'none'} 
                            text_align={'center'} 
                            width={30}
                            margin={'0'}/>
                        <p>{`days`}</p>
                    </Row>
                    <Row margin={'8px 0 0 0'}>
                        <p>{data?.list.length} {'exercises'}</p>
                    </Row>
                </Column>
            </Row>
            <Row>
                <Column width={'100%'}>
                <Row padding={'0'} width={'215px'} onClick={() => setShowPart(!showPart)}>
                    {!showPart ? <MdOutlineCheckBoxOutlineBlank size={20}/> : <MdOutlineCheckBox size={20}/>}
                    <p>Display exercise parts title</p>
                </Row>
                    <h4 style={{marginBottom: 10}}>Workout content</h4>
                    <Row padding={'0'} justify={'start'} style={{background: theme.colors.textBg, borderRadius: 10, padding: 5}}>
                        {data?.list.map(list => {
                            const l = Object.values(list)[0]
                            const content = l.map(item => {
                                (item?.part_of_workout_name && (data.list.length > partnames.length) )&& (partnames.push(item.part_of_workout_name))
                                return (
                                    <div>
                                        {item?.part_of_workout_name && (
                                            <h5 style={{marginBottom: 10, textTransform: 'uppercase', color: !showPart && (theme.colors.disabledText)}}>{item.part_of_workout_name}</h5>
                                        )}
                                        {item?.NameOfExercise && (
                                            <p style={{color: theme.colors.disabledText}}>{item.NameOfExercise}</p>
                                        )}
                                    </div>
                                )
                            })

                            return(
                                <Column margin={'0 10px 0 0'} borderRadius={'10px'}>
                                    {content}
                                </Column> 
                            )
                        })}
                    </Row>
                </Column>
            </Row>
            <Row>
                <Column width={'100%'}>
                    <h5>Description</h5>
                    <StyledTextArea
                        value={newData.description} 
                        onChange={(e) => setNewData({...newData, description: e.target.value})}
                    />
                </Column>
            </Row>
            <ButtonContainer >
                <Row padding={'0'} width={'160px'} onClick={() => ShowTerms()}>
                    {!termReaded ? <MdOutlineCheckBoxOutlineBlank size={30}/> : <MdOutlineCheckBox size={30}/>}
                    <p>Accept the terms</p>
                </Row>
                <CustomButton
                    button={StyledSubmitButton}
                    onClick={() => handleOnPublish()}
                    label={"Publish"}
                    style={{width: '100px'}} 
                    disabled={!termReaded}/>
            </ButtonContainer>
          <CustomPopUp content={description} isOpen ={showInfo} setIsOpen={() => ShowTerms()} div={DescriptionContainer}/>
        </Container>
    )
}

export default PublishDetails;