import styled from "styled-components";

export const Row = styled.div`
  width: ${({width}) => width || '100%'};
  // max-height: 250px;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justify || 'space-between'};
  align-items: ${({align}) => align || 'center'};
  margin: ${({margin}) => margin && (margin)};
  padding: ${(props) => props.padding || '0 1em 0 1em'};
  overflow-y: ${({scroll}) => scroll && (scroll)};


  @media screen and (max-width: 600px) { // mobile screen
    justify-content: ${({justify_mobile}) => justify_mobile || 'space-between'};

    & > h3 {
      color:${({color}) => color && (color)};
    }
  }

`;

export const  Column = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: ${({justify}) => justify && (justify)};
  height: ${({height}) => height || '100%'};
  width: ${({width}) => width && (width)};
  margin: ${({margin}) => margin && (margin)};
  background:${({bg}) => bg && (bg)};
  border-radius:${({borderRadius}) => borderRadius && (borderRadius)};
`