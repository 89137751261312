import styled from "styled-components";

export const MainContainer = styled.div`    
      height: 100vh; /* Full height of viewport */

    @media screen and (min-width: 600px) { // desktop
      padding:0 5em 0 5em;
    }
    // @media screen and (min-width: 5000px) {
    //   scroll-snap-type: y mandatory; /* Enable scroll snapping */
    //   overflow-y: scroll; /* Enable vertical scrolling */
    // }
`;

export const MainSection = styled.section`
    display: flex;
    flex-direction: column;
    // align-items: center;
    // overflow:auto;

    @media screen and (min-width: 600px) { // desktop
        // position: relative;
        // height: 100vh;
        // scroll-snap-align: end;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        //align-items: ${(props) => props.align || 'center'};
        // z-index:-2;
    }
`;

export const TopTriangle = styled.div`
@media screen and (min-width: 600px) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 100% 30%, 0 100%);
  filter: blur(0.5px);
  z-index:-1;
  opacity: 0.4;
}
`;



export const TopSquare = styled.div`
@media screen and (max-width: 600px) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  z-index:-1;
  opacity: 0.6;
}
`;