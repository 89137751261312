import React, { useEffect, useState } from 'react';
import styled,  { keyframes } from 'styled-components';
import { Row } from '../components/style/Row.styled';

const show = keyframes`
  0% {margin-top:-245px;}
  5% {margin-top:-165px;} // 3 section
  33% {margin-top:-165px;} // 3 section
  38% {margin-top:-80px;} // 2 section
  66% {margin-top:-80px;} // 2 section
  71% {margin-top:1px;} // 1 section
  99.99% {margin-top:1px;} // 1 section
  100% {margin-top:-245px;}
`;

const showMobile = keyframes`
  0% { margin-top: -90px; }    // Alku, yläosassa piilossa
  10% { margin-top: -59px; }   // 3. osio
  40% { margin-top: -59px; }   // 3. osio näkyvissä pidempään
  45% { margin-top: -28px; }   // 2. osio
  75% { margin-top: -28px; }   // 2. osio näkyvissä pidempään
  80% { margin-top: 0px; }     // 1. osio
  100% { margin-top: 0px; }    // 1. osio näkyvissä lopussa
`

const TextContainer = styled.div`
  color: ${({theme}) => theme.colors.text};
  text-transform: uppercase;
  font-size:2rem;
  font-weight:bold;
  display:block;

  @media screen and (max-width: 600px) { // mobile screen
      font-size: 20px;
  }
`;

const Flip = styled.div`
  height: 50px;
  overflow: hidden;

  @media screen and (max-width: 600px) { // mobile screen
    height: 30px;
  }

  & > div > div {
    padding: 4px 5px;
    height: 45px;
    margin-bottom: 45px;
    display: inline-block;
    font-size: 2rem;
    font-weight: bold;
    background: linear-gradient(90deg, #7fb6d5, ${({theme}) => theme.colors.text});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media screen and (max-width: 600px) { // mobile screen
      font-size: 20px;
      margin-bottom: 10px;
      display: inline-block;
      height: 25px;
    }
  }

  & div:first-child {
    animation: ${show} 10s linear infinite;

    @media screen and (max-width: 600px) { // mobile screen
      animation: ${showMobile} 10s linear infinite;
    }
  }
`;

const HeaderText = ({theme}) => {

  return (
      <TextContainer theme={theme}>
        <Row padding={'0px 2.5em 0px 0px'} >
          Work out
          <Flip theme={theme}>
              <div><div>at Home</div></div>
              <div><div>at The gym</div></div>
              <div><div>Anywhere</div></div>
          </Flip>
        </Row>
        <Row>
          Nosvitale is with you!
        </Row>
      </TextContainer>
  );
};

export default HeaderText;
