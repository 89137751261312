import styled from 'styled-components';

export const ColumnWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 29em;
  margin: 10px 0 10px 0;
  // background: ${({theme}) => theme.colors.textBg};
`;