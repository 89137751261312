import React from "react";
//utils
import { GetdecodeToken } from "./Utils";

const roles = {
    basic: [],
    identified: ['basic'],
    premium: ['identified'],
    admin: ['premium']
  };

export const HasRole = async (requiredRole) => {
    const userRole = await GetRole();

    return checkRole(userRole, requiredRole);
  };

  const checkRole = (userRole, requiredRole) => {
    if (userRole === requiredRole) return true;
    const inheritedRoles = roles[userRole] || [];

    for (let role of inheritedRoles) {
        if (checkRole(role, requiredRole)) {
            return true;
        }
    }
    return false;
};

  export const GetRole = async () => {
    const decoded = await GetdecodeToken();
    const role = decoded?.role;
  
    return role
  }

  