import React, {useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

//components
import PersonalExercises from "./PersonalExercises.jsx";
import WorkoutNav from '../WorkoutNav.jsx'
import { AddButton } from "../../../components/Buttons.jsx";
//style
// import { fetchPersonalData } from "../../../Utils/ApiRequests.jsx";
import {WorkoutContainer} from '../StyledComponents.jsx'
import { StyledAnalyticsButton } from "../../../components/style/Buttons.styled.jsx";
import { CustomButton } from "../../../components/Buttons.jsx";
import { RightBottomFloatingContainer, ColumnContainer } from "../../../components/style/Container.styled.jsx";
//utils
import { Loading } from "../../../Utils/Utils.jsx";
import { useData } from "../../../Utils/UseContext.jsx";
//icons
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { MdAdd } from "react-icons/md";


const PersonalWorkout = ({theme}) => {
  const navigate = useNavigate();
  const {personalData, setPersonalData} = useData();


  return(
      <WorkoutContainer>
        {/* <WorkoutNav theme={theme}/> */}
        {/* <Loading data={personalData?.data}> */}
            <PersonalExercises personalData = {personalData?.data} setPersonalData={setPersonalData}/>
        {/* </Loading> */}
        <RightBottomFloatingContainer>
          <ColumnContainer>
            <CustomButton button={StyledAnalyticsButton} onClick = {() => {toast.info('Upcoming features!')}} label={<TbBrandGoogleAnalytics size={20}/>}/>
            <AddButton onClick={() => navigate(`/workout/new`)}/>
          </ColumnContainer>
        </RightBottomFloatingContainer>
      </WorkoutContainer>
  )
}

export default PersonalWorkout;