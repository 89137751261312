import React, {useState, useEffect} from "react";
import { Navigate, useLocation, useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
//Utils
import { useAuth, useData } from "../../../Utils/UseContext.jsx";
import { Loading, DateNow, DateToString, AddTokenToHeaders} from "../../../Utils/Utils.jsx";
//API
import { ConfirmAccessRight } from "../../../Utils/ApiRequests.jsx";
//COmponents
import { Modal, ScrollView, ExpiryDate, CustomPopUp} from "../../../components/Components.jsx";
import { GetPublicData } from "../../../Utils/ApiRequests.jsx";
import { WorkoutMedia } from "../WorkoutMedia.jsx";
import PreviewScreen from "./PublicPreviewScreen.jsx";
//icon
import { MdOutlineReadMore } from "react-icons/md";
//Style
import { ButtonContainer, Container100, PublishDetailsContainer, DescriptionContainer } from "../../../components/style/Container.styled.jsx";
import { Column, Row } from "../../../components/style/Row.styled.jsx";
import { StyledSubmitButton } from "../../../components/style/Buttons.styled.jsx";
import { WorkoutRow, WorkoutContent, WorkoutContainer, ExerciseContainer} from '../StyledComponents.jsx'





const PublicExerciseRow = ({nameofcreator, partnames, title, price, expiredIn, media, onClick}) => {
   
     return(
       <WorkoutRow columns={'0.5fr 0.9fr 1.1fr 0.40fr'} onClick={onClick}>
       <WorkoutMedia src={media} type="image"/>
       <WorkoutContent align={'flex-start'} justify={'space-between'} font={10}>
         <h4>{title}</h4>
         <p>{partnames.length} part exercise</p>
         <p>Created by {nameofcreator}</p>
       </WorkoutContent>
       <WorkoutContent align={'flex-start'} justify={'center'} >
         <p>{'Expires in'} {expiredIn} {'days'}</p>
         <p>{'Price'}: {price}{'€'}</p>
       </WorkoutContent>
       <WorkoutContent align={'flex-start'} justify={'center'} font={10}>
        <MdOutlineReadMore size={30}/>
        <p>More...</p>
       </WorkoutContent>
     </WorkoutRow>
     )
   }
  
export const PublicExerciseSection = ({data, theme}) =>{
      const navigate = useNavigate();
      const {state} = useAuth();
      const location = useLocation();
      const {value} = location.state || {};
      const [isOpen, setIsOpen] = useState(false);
      const [previewData, setPreviewData] = useState(null);

      useEffect(() => {
        if(value !== undefined){
          openPreview(value)
        }

      },[location.state !== null])

      const closeModal = () => {
        setTimeout(() => {
          setIsOpen(false);
        }, 0);
      };
      const openPreview = (data) => {
        if(state.isAuthenticated){
          console.log("avattu: ", data)
          setPreviewData(data)
          setIsOpen(true);
        }else{
          toast.info("Please, sign in.");
        }

      }

      const publicExercise = Object.values(data).map(item => {
        const workout_name = item?.workout_name;
        const username = item?.username;
        const workout_id = item?.workout_id;
        const description = item?.description;
        const expired_time = item?.expired_time;
        const price = item?.price;
        const media = item?.media_url;
        const display_parts = item?.display_parts;
        const part_names = item?.part_names;
  
        return (
          <PublicExerciseRow key={Math.random()} 
            onClick={() => navigate(`/workout/public/${workout_id}`)} 
            id = {workout_id} nameofcreator = {username} title = {workout_name} partnames={part_names} price = {price} expiredIn = {expired_time} description = {description} media={media} display_parts={display_parts}/>
        )
      })
  
      return (
        <ExerciseContainer justify={'start'} >
          {publicExercise}
          <Modal
            isOpen={isOpen}
            onClose={() => closeModal()}
            children = {
              <PreviewScreen 
                id={previewData?.workout_id} 
                nameofcreator={previewData?.username} 
                partnames={previewData?.part_names} 
                title={previewData?.workout_name} 
                price={previewData?.price} 
                expiredIn={previewData?.expired_time} 
                description={previewData?.description} 
                media={previewData?.media} 
                display_parts={previewData?.display_parts} 
                currency={previewData?.currency} 
                closeModal={closeModal}
                theme={theme}/>
            }
          />
        </ExerciseContainer>
        );
   }

const PublicWorkout = ({theme}) => {
    const {publicData, setPublicData} = useData();


    useEffect(() => {
      console.log("lokitus tarkistamaan, ettei jää looppiin")
        GetPublicData(setPublicData);
     },[])

    return (
        <WorkoutContainer>
          <h2>{'Public workout'}</h2>
          <Loading data={publicData?.data} timeout={1000}>
            <PublicExerciseSection data = {publicData?.data} theme={theme}/>
          </Loading>
        </WorkoutContainer>
    )
}

export default PublicWorkout;