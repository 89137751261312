import React, {createContext, useContext, useReducer, useState, useEffect} from 'react';
import { GetCookieData, ClearLoginCookie, SetToLocalStorage, getFromLocalstorage } from './Utils';
import { fetchPersonalData } from './ApiRequests';


let initialState = {
    isAuthenticated: false,
    token: null,
    user: null
  };

 let initialDataState = {
  data: null
 }

 let initialPublicDataState = {

 }

 let initialEventData = {

 }
  
  // Reducer-funktio käsittelemään tilan muutoksia
  const authReducer = (state, action) => {
    switch (action.type) {
      case 'LOGIN':
        // console.log(action)
        return {
          ...state,
          isAuthenticated: true,
          token: action.payload,
          user: action.user
        };
      case 'LOGOUT':
        ClearLoginCookie();
        localStorage.clear();
        sessionStorage.clear();
        return {
          ...state,
          isAuthenticated: false,
          token: null,
          user: null,
        };
      default:
        return state;
    }
  };

   const dataReducer = (state, action) => {
     switch (action.type) {
       case 'LOAD':
         return {
           ...state,
           data: action.payload
         };
       default:
         return state;
     }
   };

export const AuthContext = createContext();
export const DataContext = createContext();
export const TimerContext = createContext();


  const AuthProvider = ({ children }) => {
    initialState = GetCookieData() ? GetCookieData() : initialState;
    const [state, dispatch] = useReducer(authReducer, initialState);
    
    return (
      <AuthContext.Provider value={{ state, dispatch }}>
        {children}
      </AuthContext.Provider>
    );
  };

   const DataProvider = ({ children }) => {
    //initialDataState = getFromLocalstorage('myWorkout') ? {data : getFromLocalstorage('myWorkout')} : initialDataState;
     const [personalData, setPersonalData] = useReducer(dataReducer, initialDataState);
     const [publicData, setPublicData] = useReducer(dataReducer, initialPublicDataState);
    //  const [workoutEvents, setWorkoutEvents] = useReducer(dataReducer, initialEventData);

    useEffect(() => {
      fetchPersonalData(setPersonalData);
    }, []);
    
     return (
       <DataContext.Provider value={{ publicData, personalData, setPublicData, setPersonalData }}>
         {children}
       </DataContext.Provider>
     );
   };

   const TimerProvider = ({ children }) => {
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    
    return (
      <TimerContext.Provider value={{ isTimerRunning, setIsTimerRunning }}>
        {children}
      </TimerContext.Provider>
    );
  };

  const useAuth = () => {
    const context = useContext(AuthContext);
    
    if (!context) {
      throw new Error('useAuth hook voidaan käyttää vain AuthProviderin sisällä');
    }
    // console.log("logged in: ", context)
    return context;
  };

   const useData = () => {
     const context = useContext(DataContext);

     if (!context) {
       throw new Error('useData hook voidaan käyttää vain DataProviderin sisällä');
     }
     return context;
   };

   const useTimer = () => {
    const context = useContext(TimerContext);
    if(!context){
      throw new Error('useTimer hook voidaan käyttää vain TimerProviderin sisällä')
    }
    return context;
   }


  export {AuthProvider, useAuth, DataProvider, useData, TimerProvider, useTimer}