import React, { useEffect, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import moment from 'moment';
import { Chart as ChartJS, CategoryScale, BarElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
//utils
import { FilterEventsByDateRange, GetWeekRange } from '../../Utils/Utils';
// input
import { StyledCustomInput } from '../../components/style/CustomInput.styled';
//style
import { Row } from '../../components/style/Row.styled';

// Rekisteröi Chart.js komponentit
// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
ChartJS.register(BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const TrainingGraph = ({ data }) => {
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [range, setRange] = useState({ startDate: GetWeekRange().startOfWeek, endDate: GetWeekRange().endOfWeek }); // Oletusväli (nykyinen päivä)
  const [viewMode, setViewMode] = useState('day'); // 'day', 'week', 'month'

  useEffect(() => {
    setSelectedExercises(exercises)
  },[data])

  const handleExerciseChange = (exercise) => {
    setSelectedExercises(prev => 
      prev.includes(exercise) ? prev.filter(item => item !== exercise) : [...prev, exercise]
    );
  };

  const exercises = Array.from(new Set(data.map(item => item.part_of_workout_name)));
  const filteredData = FilterEventsByDateRange(data, {startOfWeek: range.startDate, endOfWeek: range.endDate});
  const labels = getLabels(filteredData, viewMode);

    // Data for bar chart (number of workouts)
    const barData = labels.map(label => {
      return selectedExercises.reduce((total, exercise) => {
        const exerciseData = filteredData.filter(item => item.part_of_workout_name === exercise);
        return total + exerciseData.filter(item => matchesViewMode(item, label, viewMode)).length;
      }, 0);
    });

      // Data for line chart (duration of workouts)
  const lineData = labels.map(label => {
    return selectedExercises.reduce((totalDuration, exercise) => {
      const exerciseData = filteredData.filter(item => item.part_of_workout_name === exercise);
     
      const durationSum = exerciseData
        .filter(item => matchesViewMode(item, label, viewMode))
        .reduce((sum, item) => sum + item.duration, 0);
      return totalDuration + (durationSum / (60 * 1000));
    }, 0);
  });

  // LINE DATASET
  // const chartData = {
  //   labels: labels,
  //   datasets: selectedExercises.map(exercise => {
  //     const exerciseData = filteredData.filter(item => item.part_of_workout_name === exercise);
  //     const groupedData = groupDataByViewMode(exerciseData, labels, viewMode);
  //     return {
  //       label: exercise,
  //       data: groupedData,
  //       fill: false,
  //       borderColor: getRandomColor(),
  //       backgroundColor: getRandomColor(),
  //     };
  //   }),
  // };

  const chartData = {
    labels: labels,
    datasets: [
      {
        type: 'bar',
        label: 'Workouts',
        data: barData,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        yAxisID: 'y-axis-1',
      },
      {
        type: 'line',
        label: 'Duration (min)',
        data: lineData,
        fill: false,
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        yAxisID: 'y-axis-2',
      },
    ],
  };

  // LINE OPTIONS
  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'top',
  //     },
  //     title: {
  //       display: true,
  //       text: `${range.startDate.toLocaleDateString()} - ${range.endDate.toLocaleDateString()}`,
  //     },
  //   },
  // };

  const options = {
    responsive: true,
    scales: {
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Number of Workouts',
        },
      },
      'y-axis-2': {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Duration of Workouts (min)',
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Training data from ${range.startDate.toLocaleDateString()} to ${range.endDate.toLocaleDateString()}`,
      },
    },
  };

  const handleRangeChange = (start, end) => {
    if(viewMode === "month"){
      console.log("end: ", new Date(end).getMonth(), " Start: ", start);
      // setRange({ startDate: new Date(), endDate: new Date(end) });

    }
    setRange({ startDate: new Date(start), endDate: new Date(end) });
  };

  const handleViewModeChange = (e) => {
    console.log("VIEW MODE: ", e.target.value)
    setViewMode(e.target.value);
  };

  return (
    <div>
      <Row>
        <label style={{marginRight:5 }}>
          Start Date: 
          <StyledCustomInput 
            type="date" 
            onChange={e => handleRangeChange(e.target.value, range.endDate)} 
            value={range.startDate.toISOString().split('T')[0]} 
          />
        </label>
        <label style={{marginLeft:5 }}>
          End Date: 
          <StyledCustomInput 
            type="date" 
            onChange={e => handleRangeChange(range.startDate, e.target.value)} 
            value={range.endDate.toISOString().split('T')[0]} 
          />
        </label>
        <label style={{marginLeft:5 }}>
          View:
          <select value={viewMode} onChange={handleViewModeChange} style={{background: 'transparent', borderRadius: 5, height: '2.5em', marginBottom: 5}}>
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
          </select>
        </label>
      </Row>
      <Row>
        {exercises.map(exercise => (
          <label key={exercise}>
            <input 
              type="checkbox" 
              checked={selectedExercises.includes(exercise)}
              onChange={() => handleExerciseChange(exercise)}
            />
            {exercise}
          </label>
        ))}
      </Row>
      {/* <Line data={chartData} options={options} /> */}
      <Bar data={chartData} options={options} />
    </div>
  );
};

export const WeightGraph = ({data}) => {
  const exercises = Array.from(new Set(data.map(item => item.exercise_array)));
  console.log("Exercises: ",exercises)
}

// Apufunktio satunnaisen värin luomiseen
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Funktio labelsien saamiseksi viewModen mukaan
const getLabels = (data, viewMode) => {
  switch (viewMode) {
    case 'day':
      return Array.from(new Set(data.map(item => moment(item.date).format('YYYY-MM-DD')))).sort();
    case 'week':
      return Array.from(new Set(data.map(item => moment(item.date).isoWeek()))).sort((a, b) => a - b);
    case 'month':
    default:
      return Array.from(new Set(data.map(item => moment(item.date).format('YYYY-MM')))).sort();
  }
};

// Funktio datan ryhmittämiseen viewModen mukaan
const groupDataByViewMode = (data, labels, viewMode) => {
  return labels.map(label => {
    switch (viewMode) {
      case 'day':
        return data.filter(item => moment(item.date).format('YYYY-MM-DD') === label).length;
      case 'week':
        return data.filter(item => moment(item.date).isoWeek() === label).length;
      case 'month':
      default:
        return data.filter(item => moment(item.date).format('YYYY-MM') === label).length;
    }
  });
};

// Funktio tarkistamaan onko item valitussa viewModessa
const matchesViewMode = (item, label, viewMode) => {
  switch (viewMode) {
    case 'day':
      return moment(item.date).format('YYYY-MM-DD') === label;
    case 'week':
      return moment(item.date).isoWeek() === label;
    case 'month':
    default:
      return moment(item.date).format('YYYY-MM') === label;
  }
};


