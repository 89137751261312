import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';

export const WorkoutMedia = ({ src, width, height }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [mediaSrc, setMediaSrc] = useState('');
 

const getMediaType = (mediaData) => {
  // Tarkistetaan alkaako mediaData "data:"-alkuosalla

  if(!mediaData) return;

  if (!mediaData.startsWith("data:")) {
    throw new Error("Invalid media data format");
  }

  // Jaetaan merkkijono "data:"-alkuosan jälkeen olevan osan perusteella
  const mediaTypePart = mediaData.split(";")[0]; // Esim. "data:video/quicktime"
  
  // Palautetaan media-tyyppi
  return mediaTypePart.replace("data:", ""); // Poistetaan "data:"-alkuosa
};

const type = getMediaType(src);

  const openModal = (src) => {
    setMediaSrc(src);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setMediaSrc('');
  };
 
  return (
    <>
      <Thumbnail
        onClick={() => openModal(src)}
        style={{ backgroundImage: `url(${src})` }}
        width={width}
        height={height}
      >
        {type?.startsWith('video/') && <PlayButton>▶️</PlayButton>}
      </Thumbnail>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Media Modal"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          }
        }}
      >
        {type === 'video' ? (
          <video width="100%" height="100%" controls autoPlay>
            <source src={mediaSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={mediaSrc} alt="Media Preview" style={{ width: '100%', height: '100%' }} />
        )}
        <button onClick={closeModal}>Close</button>
      </Modal>
    </>
  );
};

const Thumbnail = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  width: ${({width}) => width || '80px'};
  height: ${({height}) => height || '80px'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayButton = styled.div`
  font-size: 24px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
