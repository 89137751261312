import styled, {keyframes} from 'styled-components';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 20%;
  }

  100% {
    background-position: 100% 20%;
  }
`;

export const MainDescriptionP = styled.p`
    width: 60vw;

    & span.gradient-text {
        background: linear-gradient(90deg, ${({theme}) => theme.colors.text}, #7fb6d5, ${({theme}) => theme.colors.text});
        background-size: 200%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: ${gradientAnimation} 3s linear infinite alternate;
    }
    
    & span.underline-text {
      text-decoration: underline;
    }

    @media screen and (max-width: 600px) { // mobile screen
        width: 100vw;
        font-size: 20px;
  }
`