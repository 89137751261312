
import styled from 'styled-components';


export const ContentColumn = styled.div`
    display:flex;
    flex-direction: column;
    height:100%;
    justify-content: ${({justify}) => justify || 'space-between' };
    margin: ${({margin}) => margin && (margin) };

    & > p{
        margin-bottom: 3px;
    }

    // width: 100%;
`