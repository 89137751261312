import React from "react";
import Privacy_Policy from './PrivacyPolicy.json'

//style
import { PrivacyPolicyContainer } from "../components/style/Container.styled";

const PrivacyPolicy = () => {
    // const [Privacy_Policy, setPrivacyPolicy] = useState();
    let lang = navigator.language;
    lang = Privacy_Policy?.supported[lang] ? Privacy_Policy?.supported[lang] : Privacy_Policy?.supported['en-EN']

    return(
        <>
        <PrivacyPolicyContainer>
            <h2>{Privacy_Policy.title[lang]}</h2>
            <div>
                <h3>{Privacy_Policy.section1.title[lang]}</h3>
                {Privacy_Policy.section1.content[lang]}
            </div>
            <div>
                <h3>{Privacy_Policy.section2.title[lang]}</h3>
                {Privacy_Policy.section2.content[lang]}
            </div>
            <div>
                <h3>{Privacy_Policy.section3.title[lang]}</h3>
                {Privacy_Policy.section3.content[lang]}
            </div>
            <div>
                <h3>{Privacy_Policy.section4.title[lang]}</h3>
                {Privacy_Policy.section4.content[lang].map(item => { return <li style={{listStyle: 'none', marginTop: '2px'}}>{item}</li>})}
            </div>
            <div>
                <h3>{Privacy_Policy.section5.title[lang]}</h3>
                {Privacy_Policy.section5.content[lang]}
            </div>
            <div>
                <h3>{Privacy_Policy.section6.title[lang]}</h3>
                {Privacy_Policy.section6.content[lang]}
            </div>
            <div>
            <h3>{Privacy_Policy.section7.title[lang]}</h3>
            {Privacy_Policy.section7.content[lang]}
            </div>
            <div>
            <h3>{Privacy_Policy.section8.title[lang]}</h3>
            {Privacy_Policy.section8.content[lang]}
            </div>
            <div>
            <h3>{Privacy_Policy.section9.title[lang]}</h3>
            {Privacy_Policy.section9.content[lang]}

            </div>
            <div>
            <h3>{Privacy_Policy.section10.title[lang]}</h3>
            {Privacy_Policy.section10.content[lang]}

            </div>
            <div>
            <h3>{Privacy_Policy.section101.title[lang]}</h3>
            {Privacy_Policy.section101.content[lang]}
            </div>
            <div>
            <h3>{Privacy_Policy.section102.title[lang]}</h3>
            {Privacy_Policy.section102.content[lang]}
            </div>
            <div>
            <h3>{Privacy_Policy.section1021.title[lang]}</h3>
            {Privacy_Policy.section1021.content[lang]}
            </div>
            <div>
            <h3>{Privacy_Policy.section1022.title[lang]}</h3>
            {Privacy_Policy.section1022.content[lang]}
            </div>
            <div>
            <h3>{Privacy_Policy.section1023.title[lang]}</h3>
            {Privacy_Policy.section1023.content[lang]}
            </div>
            <div>
            <h3>{Privacy_Policy.section1024.title[lang]}</h3>
            {Privacy_Policy.section1024.content[lang]}
            </div>
            <div>
            <h3>{Privacy_Policy.section103.title[lang]}</h3>
            {Privacy_Policy.section103.content[lang]}
            </div>
            <div>
            <h3>{Privacy_Policy.section104.title[lang]}</h3>
            {Privacy_Policy.section104.content[lang]}
            </div>
        </PrivacyPolicyContainer>
        </>
    )
}

export default PrivacyPolicy;