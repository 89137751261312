import React from "react";
import { useAuth } from "../Utils/UseContext.jsx";
import { useNavigate, useLocation } from "react-router-dom";
//Icon
import { MdOutlineLogin, MdOutlineLogout } from "react-icons/md";

//style
import { Styled20radiusButton } from "./style/Buttons.styled.jsx";


const LoginIcon = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = useAuth();
    const isAuthenticated = state.isAuthenticated;
  
    const handleNavigate = () => {
      if (!isAuthenticated) {
        navigate('/login');
      }
    };
    
    return(
        // <div style={{position: 'fixed' , right: '0.5em', top: 5}}>
        <>
         {(!isAuthenticated && location.pathname !== '/login')  && (
          <Styled20radiusButton bg={'transparent'} style={{cursor: 'pointer'}} onClick={() => handleNavigate()}>
            <span style={{cursor: 'pointer'}}>{'Sign in'}</span>
            <MdOutlineLogin color={'white'} size={20} style={{cursor: 'pointer'}}/>
          </Styled20radiusButton>
          )}
          </>
        // </div>
    )
  }

  const LogoutIcon = ({onClick}) => {
    const { state } = useAuth();
    const isAuthenticated = state.isAuthenticated;
  
    
    return(
        <>
         {isAuthenticated && (<MdOutlineLogout style={{cursor: 'pointer'}} color={'white'} size={25} onClick={onClick}/>)}
        </>
    )
  }


  export {LoginIcon, LogoutIcon};
